import {
  BottomSheet,
  BottomSheetIllustrationContent,
  IconButton,
} from "@clipboard-health/ui-components";
import { LoadingButton, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Box, Link, Stack } from "@mui/material";

import { DialogFooter } from "../../components/DialogFooter";

interface WalkInNoteDialogProps {
  modalState: UseModalState;
  workerType?: string;
  onContinue: () => void;
}

export function WalkInNoteDialog(props: WalkInNoteDialogProps) {
  const { modalState, workerType = "", onContinue } = props;

  const formatWorkerType = (types: string): string => {
    if (!types) {
      return "";
    }

    const typeArray = types.replaceAll(" ", "").split(",");
    if (typeArray.length <= 1) {
      return types;
    }

    const lastType = typeArray.pop();
    return `${typeArray.join(", ")} or ${lastType ?? ""}`;
  };

  const steps = [
    {
      pos: 1,
      body: "We'll email you a PDF once you've booked your walk-in slot. Print it and follow the email instructions.",
    },
    {
      pos: 2,
      body: `When you arrive at the facility, let the receptionist know you are there as a walk-in and ask for a ${formatWorkerType(
        workerType
      )} application.`,
    },
    {
      pos: 3,
      body: `Complete the application and then ask to be interviewed for the ${formatWorkerType(
        workerType
      )} role. If you are not interviewed, you will be reimbursed.`,
    },
  ];

  return (
    <BottomSheet
      modalState={modalState}
      onClose={() => {
        modalState.closeModal();
      }}
    >
      <IconButton
        size="small"
        iconType="close"
        variant="outlined"
        sx={{ position: "absolute", top: 24, right: 16 }}
        LinkComponent={Link}
        onClick={() => {
          modalState.closeModal();
        }}
      />
      <BottomSheetIllustrationContent
        illustrationType="facility-small"
        title={
          <Stack>
            <Box component="span">This is a</Box>
            <Box component="span">walk-in building</Box>
          </Stack>
        }
        description={
          <Stack spacing={6} sx={{ px: 6, pb: 10, pt: 2 }}>
            {steps.map((step) => (
              <Stack key={step.pos} spacing={2}>
                <Text variant="body2">Step {step.pos}</Text>
                <Text variant="body1">{step.body}</Text>
              </Stack>
            ))}
          </Stack>
        }
      />
      <DialogFooter orientation="vertical">
        <LoadingButton
          fullWidth
          variant="contained"
          size="large"
          color="primary"
          isLoading={false}
          onClick={() => {
            onContinue();
          }}
        >
          Continue
        </LoadingButton>
      </DialogFooter>
    </BottomSheet>
  );
}
