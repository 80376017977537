import { type PlacementPay } from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";

import { intersection } from "../../../lib/utils/set";
import { ShiftNameType } from "../../PlacementCandidate/types";
import { type PayRate } from "../types/payRate";

const TIME_SLOT_ORDER = ["am", "pm", "noc"];
const JOB_TYPE_ORDER = ["full_time", "part_time", "prn", "weekend_warrior"];

export interface EstimatedPaySearchCriteria {
  workplaceId: string;
  qualifications: Set<string>;
  timeSlots: Set<string>;
  jobTypes: Set<string>;
  yearsOfExperience?: number;
}

function isMatchingPayRate(
  payRate: PlacementPay,
  searchCriteria: EstimatedPaySearchCriteria
): boolean {
  const payRateQualifications = new Set<string>(payRate.attributes.qualifications);
  const payRateTimeSlots = new Set<string>(payRate.attributes.timeSlots);
  const payRateJobTypes = new Set<string>(payRate.attributes.jobTypes);

  return (
    payRate.attributes.maxYearsOfExperience >= (searchCriteria.yearsOfExperience ?? 0) &&
    intersection(payRateQualifications, searchCriteria.qualifications).size > 0 &&
    intersection(payRateTimeSlots, searchCriteria.timeSlots).size > 0 &&
    intersection(payRateJobTypes, searchCriteria.jobTypes).size > 0
  );
}

function sortPayRatesByExperienceAndSlotType(
  a: PlacementPay,
  b: PlacementPay,
  searchCriteria: EstimatedPaySearchCriteria
) {
  const {
    timeSlots: placementTimeSlots,
    jobTypes: placementJobTypes,
    yearsOfExperience: yearOfExperiences,
  } = searchCriteria;

  if (isDefined(yearOfExperiences)) {
    return (
      Math.abs(a.attributes.minYearsOfExperience - yearOfExperiences) -
      Math.abs(b.attributes.minYearsOfExperience - yearOfExperiences)
    );
  }

  // Get the first matching time slot for each pay rate
  const aTimeSlot = a.attributes.timeSlots.find((ts) => placementTimeSlots.has(ts)) ?? "";
  const bTimeSlot = b.attributes.timeSlots.find((ts) => placementTimeSlots.has(ts)) ?? "";

  // Compare time slots
  const timeSlotDiff = TIME_SLOT_ORDER.indexOf(aTimeSlot) - TIME_SLOT_ORDER.indexOf(bTimeSlot);
  if (timeSlotDiff !== 0) {
    return timeSlotDiff;
  }

  // If time slots are equal, compare job types
  const aJobType = a.attributes.jobTypes.find((jt) => placementJobTypes.has(jt)) ?? "";
  const bJobType = b.attributes.jobTypes.find((jt) => placementJobTypes.has(jt)) ?? "";

  return JOB_TYPE_ORDER.indexOf(aJobType) - JOB_TYPE_ORDER.indexOf(bJobType);
}

function zeroToUndefined(value: number | undefined) {
  return value === 0 ? undefined : value;
}

function findMatchingPayRate(
  searchCriteria: EstimatedPaySearchCriteria,
  payRatesForWorkplace: PlacementPay[]
): PlacementPay | undefined {
  const matchingPayRates = payRatesForWorkplace
    .filter((payRate) => isMatchingPayRate(payRate, searchCriteria))
    .sort((a, b) => sortPayRatesByExperienceAndSlotType(a, b, searchCriteria));

  return matchingPayRates[0];
}

export function getEstimatedPay(
  searchCriteria: EstimatedPaySearchCriteria,
  payByWorkplace: Record<string, PlacementPay[]>
): PayRate | undefined {
  const payRatesForWorkplace = payByWorkplace[searchCriteria.workplaceId];
  if (!payRatesForWorkplace) {
    return undefined;
  }

  const matchingPayRate = findMatchingPayRate(searchCriteria, payRatesForWorkplace);
  if (matchingPayRate) {
    const payRateQualifications = new Set<string>(matchingPayRate.attributes.qualifications);
    const payRateTimeSlots = new Set<string>(matchingPayRate.attributes.timeSlots);
    const payRateJobTypes = new Set<string>(matchingPayRate.attributes.jobTypes);

    return {
      exact: zeroToUndefined(matchingPayRate.attributes.exactPayRate),
      min: zeroToUndefined(matchingPayRate.attributes.minPayRate),
      max: zeroToUndefined(matchingPayRate.attributes.maxPayRate),
      rateIncludesBenefits: matchingPayRate.attributes.includesBenefits,
      benefitsDiff: zeroToUndefined(matchingPayRate.attributes.benefitsDiff),
      weekendDiff: zeroToUndefined(matchingPayRate.attributes.weekendDiff),
      shiftTypeDiff: {
        [ShiftNameType.AM]: zeroToUndefined(matchingPayRate.attributes.dayDiff),
        [ShiftNameType.PM]: zeroToUndefined(matchingPayRate.attributes.eveningDiff),
        [ShiftNameType.NOC]: zeroToUndefined(matchingPayRate.attributes.nightDiff),
      },
      externallyDerived: true,
      matchedQualifications: intersection(searchCriteria.qualifications, payRateQualifications),
      matchedTimeSlots: intersection(searchCriteria.timeSlots, payRateTimeSlots),
      matchedJobTypes: intersection(searchCriteria.jobTypes, payRateJobTypes),
      obscured: false,
    };
  }

  return undefined;
}
