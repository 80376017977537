import { type PlacementPay } from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";
import { useMemo } from "react";

import { useGetPayTransparencyData } from "../api/useGetPayTransparencyData";
import type { GetPlacementsResponse, PlacementData } from "../types/fetchPlacements.schema";
import type { PayRate } from "../types/payRate";
import { type EstimatedPaySearchCriteria, getEstimatedPay } from "./estimatedPay";
import { type NudgeType, type SurveyForm, usePayTransparencyFlag } from "./usePayTransparencyFlag";

export interface PayRateMeta {
  userContributionCount: number;
  minYearsOfExperienceSet: Set<number>;
}

interface UseComputedPayData {
  isLoading: boolean;
  payData: Map<string, PayRate | undefined>;
  payDataMeta: Map<string, PayRateMeta>;
  maxResults?: number;
  nudge?: NudgeType;
  surveyForm?: SurveyForm;
  surveySheetId?: string;
  bonusAmountInMinorUnits: number;
  userYearsOfExperience?: number;
}

function mapPayRange(placement: PlacementData): PayRate {
  return {
    min: placement.attributes.payRange?.minPay?.amountInMinorUnits,
    max: placement.attributes.payRange?.maxPay?.amountInMinorUnits,
    externallyDerived: false,
  };
}

function mapPlacementToCriteria(
  placement: PlacementData,
  userYearsOfExperience?: number
): EstimatedPaySearchCriteria {
  return {
    workplaceId: placement.relationships.workplace.data.id,
    qualifications: new Set(placement.attributes.workerTypes),
    timeSlots: new Set(placement.attributes.shiftTypes),
    jobTypes: new Set(placement.attributes.jobTypes),
    yearsOfExperience: userYearsOfExperience,
  };
}

function payDataFromPlacements(
  placements: GetPlacementsResponse["data"]
): Map<string, PayRate | undefined> {
  return new Map(placements.map((placement) => [placement.id, mapPayRange(placement)]));
}

function payDataFromPlacementsWithEstimatedPay({
  placements,
  payDataByWorkplace,
  obscureEstimates,
  userYearsOfExperience,
}: {
  placements: GetPlacementsResponse["data"];
  payDataByWorkplace: Record<string, PlacementPay[]>;
  obscureEstimates: boolean;
  userYearsOfExperience?: number;
}): Map<string, PayRate | undefined> {
  return new Map(
    placements.map((placement) => {
      const estimatedPay = getEstimatedPay(
        mapPlacementToCriteria(placement, userYearsOfExperience),
        payDataByWorkplace
      );

      if (obscureEstimates && isDefined(estimatedPay)) {
        return [
          placement.id,
          {
            externallyDerived: true,
            obscured: true,
          },
        ];
      }

      return [placement.id, estimatedPay ?? mapPayRange(placement)];
    })
  );
}

export function useComputedPayData(
  placements: GetPlacementsResponse["data"],
  yearsOfExperienceOverride?: number
): UseComputedPayData {
  const payTransparency = usePayTransparencyFlag();

  const {
    payDataByWorkplace,
    userHasSharedPayData,
    userYearsOfExperience,
    isLoading: isTransparencyLoading,
  } = useGetPayTransparencyData({ enabled: payTransparency.enabled });

  const payData = useMemo<Map<string, PayRate | undefined>>(() => {
    if (
      !payTransparency.enabled ||
      (!userHasSharedPayData && payTransparency.nudge?.kind !== "BLUR")
    ) {
      return payDataFromPlacements(placements);
    }

    const yearsOfExperience = isDefined(yearsOfExperienceOverride)
      ? yearsOfExperienceOverride
      : userYearsOfExperience;

    if (userHasSharedPayData) {
      return payDataFromPlacementsWithEstimatedPay({
        placements,
        payDataByWorkplace,
        obscureEstimates: false,
        userYearsOfExperience: yearsOfExperience,
      });
    }

    const obscureEstimates = !userHasSharedPayData && payTransparency.nudge?.kind === "BLUR";
    return payDataFromPlacementsWithEstimatedPay({
      placements,
      payDataByWorkplace,
      obscureEstimates,
      userYearsOfExperience: yearsOfExperience,
    });
  }, [
    placements,
    payTransparency,
    userHasSharedPayData,
    payDataByWorkplace,
    userYearsOfExperience,
    yearsOfExperienceOverride,
  ]);

  const payDataMeta = useMemo(() => {
    return new Map(
      placements
        .filter((placement) =>
          isDefined(payDataByWorkplace[placement.relationships.workplace.data.id])
        )
        .map<[string, PayRateMeta]>((placement) => [
          placement.id,
          {
            userContributionCount:
              payDataByWorkplace[placement.relationships.workplace.data.id].length,
            minYearsOfExperienceSet: new Set(
              payDataByWorkplace[placement.relationships.workplace.data.id].map(
                (payData) => payData.attributes.minYearsOfExperience
              )
            ),
          },
        ])
    );
  }, [payDataByWorkplace, placements]);

  return {
    payData,
    payDataMeta,
    userYearsOfExperience,
    isLoading: payTransparency.enabled && isTransparencyLoading,
    nudge: !userHasSharedPayData && payTransparency.enabled ? payTransparency.nudge : undefined,
    surveyForm: payTransparency.enabled ? payTransparency.surveyForm : undefined,
    surveySheetId: payTransparency.enabled ? payTransparency.surveySheetId : undefined,
    bonusAmountInMinorUnits: payTransparency.enabled
      ? payTransparency.bonusAmountInMinorUnits
      : 2000,
  };
}
