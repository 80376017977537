import { Card } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";

import { Button } from "../../components/Button";

interface UnlockEstimatesCardProps {
  onStartSurvey: () => void;
  bonusAmountInMinorUnits: number;
}

export function UnlockEstimatesCard({
  onStartSurvey,
  bonusAmountInMinorUnits,
}: UnlockEstimatesCardProps) {
  return (
    <Card
      sx={(theme) => ({
        p: 2,
        background: `linear-gradient(180deg, ${String(theme.palette.info.light)} 19.5%, ${String(
          theme.palette.background.tertiary
        )} 100%)`,
      })}
    >
      <CardContent>
        <Stack spacing={2}>
          <Text semibold variant="h5">
            Unlock hourly pay estimates
          </Text>

          <Text variant="body1">
            Tell others what you have been paid to see community-sourced pay estimates on all jobs
          </Text>
        </Stack>
        <Button
          variant="contained"
          size="small"
          sx={{ mt: 8 }}
          onClick={() => {
            onStartSurvey();
          }}
        >
          Earn {formatCentsAsUsd(bonusAmountInMinorUnits, { maximumFractionDigits: 0 })} now
        </Button>
      </CardContent>
    </Card>
  );
}
