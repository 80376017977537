import { type cbhFeatureFlags } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";
import { type z } from "zod";

import { CbhFeatureFlag } from "../../../FeatureFlags/CbhFeatureFlag";

type HiringReferral = z.infer<(typeof cbhFeatureFlags)[CbhFeatureFlag.HIRING_REFERRAL]>;

export function useHiringReferralFlag(): HiringReferral {
  return useCbhFlag(CbhFeatureFlag.HIRING_REFERRAL, {
    defaultValue: {
      profileActivationBonus: {
        enabled: false,
        bonusAmountInUnits: 5000,
        referralBonusAmountInUnits: 5000,
      },
      signOnBonus: {
        enabled: false,
        bonusAmountInUnits: 50_000,
        referralBonusAmountInUnits: 50_000,
      },
      redeemedCutoffDate: "2025-03-20T09:27:38.808Z",
      bannerPositions: [],
    },
  });
}
