import { Card } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Skeleton, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { HCF_PROFILE_SOURCE } from "@src/constants";
import { generatePath, useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import { type PlacementCandidate } from "../../PlacementCandidate/types";
import { useWorkplaceWithShiftCount } from "../../Shift/Open/useWorkplaceWithShiftCount";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../../ShiftDiscovery/Filters/constants";
import { type PlacementWithDetails } from "../api/useFetchPaginatedPlacements";
import { getCurrentWorkerTrialsDateRange } from "../utils";

interface TryAShiftCardProps {
  placementDetails: PlacementWithDetails;
  placementCandidate: PlacementCandidate | undefined;
}

// e.g.
// 1 shift available
// 5 shifts available
// 10+ shifts available
// 20+ shifts available
function getShiftCountLabel(perDiemOpenShiftCount: number) {
  const perDiemOpenShiftCountRounded =
    perDiemOpenShiftCount < 10
      ? perDiemOpenShiftCount
      : Math.round(Math.floor(perDiemOpenShiftCount / 10) * 10);

  return `${perDiemOpenShiftCountRounded}${
    perDiemOpenShiftCountRounded === perDiemOpenShiftCount ? "" : "+"
  } shift${perDiemOpenShiftCount === 1 ? "" : "s"} available this week`;
}

export function TryAShiftCard({ placementDetails, placementCandidate }: TryAShiftCardProps) {
  const history = useHistory();
  const worker = useDefinedWorker();

  const enableWorkerTrialsFf = useCbhFlag(CbhFeatureFlag.PLACEMENT_WORKER_TRIALS, {
    defaultValue: false,
  });

  const {
    data: perDiemWorkplaceWithShiftCountResult,
    isLoading: perDiemWorkplaceWithShiftCountIsLoading,
  } = useWorkplaceWithShiftCount(
    {
      range: getCurrentWorkerTrialsDateRange(),
      coordinates: worker.geoLocation?.coordinates ?? [],
      distance: worker.preference?.distance ?? DEFAULT_DISTANCE_IN_MILES_FILTER,
      qualification: worker.preference?.qualification,
      specialities: worker.specialities,
      isAgent: true,
      facilityId: placementDetails?.workplace.id,
    },
    { enabled: isDefined(placementDetails?.workplace.id) && enableWorkerTrialsFf }
  );

  const perDiemWorkplaceWithShiftCount = perDiemWorkplaceWithShiftCountResult?.at(0);

  if (!enableWorkerTrialsFf) {
    return null;
  }

  if (perDiemWorkplaceWithShiftCountIsLoading) {
    return <Skeleton variant="rounded" height={175} />;
  }

  if (
    !isDefined(perDiemWorkplaceWithShiftCount) ||
    !isDefined(perDiemWorkplaceWithShiftCount.shiftsCount) ||
    perDiemWorkplaceWithShiftCount.shiftsCount === 0 ||
    !isDefined(placementCandidate)
  ) {
    return null;
  }

  return (
    <Card
      sx={{
        backgroundImage: "linear-gradient(179.96deg, #AEEDF1 -5.1%, #FFFFFF 81.82%)",
      }}
    >
      <CardContent>
        <Stack spacing={8}>
          <Stack spacing={2}>
            <Text semibold variant="h5" sx={(theme) => ({ mb: theme.spacing(2) })}>
              Try a shift at {placementDetails.workplace.name}
            </Text>
            <Text sx={(theme) => ({ color: theme.palette.text.secondary })} variant="body2">
              Get paid to work a per diem shift before applying to a permanent job
            </Text>
          </Stack>
          <Stack sx={{ alignItems: "center", justifyContent: "space-between" }} direction="row">
            <Text sx={(theme) => ({ color: theme.palette.text.secondary })} variant="body2">
              {getShiftCountLabel(perDiemWorkplaceWithShiftCount.shiftsCount)}
            </Text>
            <Button
              variant="contained"
              onClick={async () => {
                logEvent(APP_V2_APP_EVENTS.PLACEMENT_DETAILS_PAGE_ACTIONS, {
                  action: "browse_shifts_button_clicked",
                  placementId: placementDetails.id,
                  placementCandidateId: placementCandidate.id,
                  source: "jobs",
                  workerId: worker.userId,
                  workplaceId: placementDetails.workplace.id,
                });
                history.push(
                  `${generatePath(DeprecatedGlobalAppV1Paths.FACILITY_DETAIL, {
                    facilityId: placementDetails.workplace.id,
                  })}?hcfProfileSource=${HCF_PROFILE_SOURCE.PERMANENT_PLACEMENTS_WORK_TRIALS}`
                );
              }}
            >
              Browse shifts
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
