import { type cbhFeatureFlags } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";
import { type z } from "zod";

import { CbhFeatureFlag } from "../../../FeatureFlags/CbhFeatureFlag";

type PayTransparencyFlag = z.infer<
  (typeof cbhFeatureFlags)[CbhFeatureFlag.PLACEMENT_PAY_RATE_EXPERIMENT]
>;

export type NudgeType = Extract<PayTransparencyFlag, { enabled: true }>["nudge"];
export type SurveyForm = Extract<PayTransparencyFlag, { enabled: true }>["surveyForm"];

export function usePayTransparencyFlag(): PayTransparencyFlag {
  return useCbhFlag(CbhFeatureFlag.PLACEMENT_PAY_RATE_EXPERIMENT, {
    defaultValue: {
      enabled: false,
    },
  });
}
