import { BottomSheet, Illustration, LoadingSpinner } from "@clipboard-health/ui-components";
import { Span, Text, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";
import { useToast } from "@src/appV2/lib/Notifications/Toasts/useToast";
import { useQueryClient } from "@tanstack/react-query";
import { type ChangeEvent, useRef } from "react";

import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { ACCEPTABLE_RESUME_UPLOAD_FILE_CONTENT_TYPES } from "../../PlacementCandidate/constants";
import { invalidatePayTransparencyData } from "../api/useGetPayTransparencyData";
import { useSubmitPayData } from "../api/useSubmitPayData";
import { useUploadPayData } from "../api/useUploadPayData";
import { usePayTransparencyFlag } from "../lib/usePayTransparencyFlag";

function StartSurveyButton({
  onClick,
  variant = "contained",
  surveyDuration = 2,
}: {
  variant?: "contained" | "outlined";
  onClick: () => void;
  surveyDuration?: number;
}) {
  return (
    <Button variant={variant} size="medium" onClick={onClick}>
      Start {surveyDuration}-minute survey
    </Button>
  );
}

function UploadOfferLetterPayStubButton({
  variant,
  onPayDataUploaded,
}: {
  variant: "contained" | "outlined";
  onPayDataUploaded: (fileName: string) => void;
}) {
  const {
    mutateAsync: uploadPayData,
    isLoading: isUploadingPayData,
    isSuccess: isUploadingPayDataSuccess,
  } = useUploadPayData();
  const fileInputRef = useRef<HTMLInputElement>(null);

  async function onFileChange(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const { fileName } = await uploadPayData({ file });
      onPayDataUploaded(fileName);
    }
  }

  const showUploadButton = !isUploadingPayData && !isUploadingPayDataSuccess;

  return (
    <Button variant={variant} size="medium" component="label">
      {showUploadButton && (
        <>
          <Span>
            Upload offer letter/pay stub &nbsp;(<strong>instant</strong>)
          </Span>
          <input
            ref={fileInputRef}
            type="file"
            accept={ACCEPTABLE_RESUME_UPLOAD_FILE_CONTENT_TYPES.join(",")}
            style={{ display: "none" }}
            onChange={async (event) => {
              await onFileChange(event);
            }}
          />
        </>
      )}
      {!showUploadButton && <LoadingSpinner size="small" variant="invert" />}
    </Button>
  );
}

export interface UnlockHourlyPayEstimatesBottomSheetProps {
  modalState: UseModalState;
  onStartSurvey: () => void;
}

export function UnlockHourlyPayEstimatesBottomSheet(
  props: UnlockHourlyPayEstimatesBottomSheetProps
) {
  const { modalState, onStartSurvey } = props;

  const { showSuccessToast } = useToast();
  const queryClient = useQueryClient();
  const payTransparency = usePayTransparencyFlag();
  const { mutateAsync: submitPayData } = useSubmitPayData();
  const payDataUpload = useCbhFlag(CbhFeatureFlag.PLACEMENT_PAY_RATE_EXPERIMENT_UPLOAD_PAY_DATA, {
    defaultValue: {
      placement: "OFF",
    },
  });

  if (!payTransparency.enabled) {
    return null;
  }

  function handleOnStartSurveyClick() {
    modalState.closeModal();
    onStartSurvey();
  }

  async function handleOnPayDataUploaded(fileName: string) {
    await submitPayData({ fileName });
    invalidatePayTransparencyData(queryClient);
    modalState.closeModal();
    showSuccessToast("Upload complete! Thank you for contributing!");
  }

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          {payDataUpload.placement === "PRIMARY" && (
            <Stack direction="column" spacing={4}>
              <UploadOfferLetterPayStubButton
                variant="contained"
                onPayDataUploaded={async (fileName) => {
                  await handleOnPayDataUploaded(fileName);
                }}
              />
              <StartSurveyButton
                variant="outlined"
                onClick={() => {
                  handleOnStartSurveyClick();
                }}
              />
            </Stack>
          )}

          {payDataUpload.placement === "SECONDARY" && (
            <Stack direction="column" spacing={4}>
              <StartSurveyButton
                onClick={() => {
                  handleOnStartSurveyClick();
                }}
              />
              <UploadOfferLetterPayStubButton
                variant="outlined"
                onPayDataUploaded={async (fileName) => {
                  await handleOnPayDataUploaded(fileName);
                }}
              />
            </Stack>
          )}

          {payDataUpload.placement === "OFF" && (
            <StartSurveyButton
              surveyDuration={2}
              onClick={() => {
                handleOnStartSurveyClick();
              }}
            />
          )}
        </DialogFooter>
      }
    >
      <DialogContent sx={{ textAlign: "center", paddingTop: 12 }}>
        <Stack direction="column" spacing={8} sx={{ alignItems: "center" }}>
          <Illustration type="unlock" />
          <Text variant="h2">Unlock hourly pay estimates</Text>
          <Text variant="body1" sx={{ textWrap: "balance" }}>
            Tell others what you have been paid to see community-sourced pay estimates on all jobs
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
